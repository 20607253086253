import { Box, Flex, Stack } from "@mantine/core";
import { IconArrowUpRight } from "public/icons/IconArrowUpRight";
import useCategories from "shared/api/categories/hooks/queries/useCategories";
import { CategoryId } from "shared/api/categories/types";
import ButtonRound from "shared/ui/Button/ButtonRound";
import { Button } from "shared/ui/Forms/Styled";
import { Picture } from "shared/ui/Picture";
import Typography from "shared/ui/Typography/Typography";
import SolkeLogo from "widgets/index/AppDescriptionPromo/pictures/solkeLogo.png";

function AppDescriptionPromo(): JSX.Element {
    const { data } = useCategories();
    const paperPenCategorySlug = data?.data.find((x) => x.id === CategoryId.PAPER_PEN)?.slug;
    const metalPenCategorySlug = data?.data.find((x) => x.id === CategoryId.METAL_PEN)?.slug;
    return (
        <Stack w={600}>
            <Flex gap={20} align="center">
                <Typography tag="mCaps" w={434} size={14}>
                    Самые низкие цены на бумажные ручки за 18.90₽
                </Typography>
                {paperPenCategorySlug && (
                    <ButtonRound
                        label="Перейти"
                        rightIcon={<IconArrowUpRight />}
                        href={{
                            pathname: "/catalog/[categoryId]",
                            query: { categoryId: paperPenCategorySlug },
                        }}
                    />
                )}
            </Flex>
            <Typography tag="display" mt={20}>
                Ручки с логотипом. Нанесение. Эксклюзивно
            </Typography>
            <Typography tag="osSemibold" mt={20} w={600}>
                Наши ручки сделаны специально под нанесение логотипа, пластиковые из высококачественного ABC пластика,
                металлические из оксидированного алюминия.
            </Typography>
            {metalPenCategorySlug && (
                <Button
                    label="Перейти к каталогу"
                    mt={30}
                    w="max-content"
                    href={{
                        pathname: "/catalog/[categoryId]",
                        query: { categoryId: metalPenCategorySlug },
                    }}
                />
            )}
            <Typography tag="osSmallRegular" mt={60} w={220}>
                Эксклюзивный дистрибьютор ручек solke
            </Typography>

            <Box mt={10}>
                <Picture src={SolkeLogo} alt="логотип компании solke" />
            </Box>
        </Stack>
    );
}
export default AppDescriptionPromo;
