import { memo } from "react";
import { StyledButton, StyledButtonProps } from "shared/ui/Button/StyledButton";

function ButtonRound<IsExternalLink extends boolean | undefined = false>(
    props: StyledButtonProps<IsExternalLink>
): JSX.Element {
    return (
        <StyledButton
            sx={{
                height: 40,
                paddingLeft: 16,
                paddingRight: 16,
                borderRadius: 160,
            }}
            variant="light"
            tag="buttonSmallCaps"
            {...props}
        />
    );
}
export default memo(ButtonRound);
