import { ReactNode } from "react";
import { Flex } from "@mantine/core";
import Typography from "shared/ui/Typography/Typography";

interface Props {
    image: ReactNode;
    title: string;
    description: string;
}
function AdvantagePromo(props: Props): JSX.Element {
    return (
        <section>
            <Flex direction="column" gap={20}>
                <Flex gap={23} align="center">
                    {props.image}
                    <Typography tag="h3">{props.title}</Typography>
                </Flex>
                <Typography tag="osSmallRegular">{props.description}</Typography>
            </Flex>
        </section>
    );
}

export default AdvantagePromo;
