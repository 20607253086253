import { dehydrate, QueryClient } from "@tanstack/react-query";
import { GetStaticProps } from "next";
import { QueryKeys } from "shared/api/keys";
import { Page } from "shared/components/Page";
import MainLayout from "shared/layouts/Main/MainLayout";
import { cacheClient } from "shared/server/cache-client";
import { handleSsrError } from "shared/utils/handleSsrError";
import MainIndex from "src/routes/index";
import { PagesMain } from "src/shared/types/navigation";

export const getStaticProps: GetStaticProps = async () => {
    try {
        const [categories, menus] = await Promise.all([cacheClient.getCategories(), cacheClient.getMenus()]);
        const queryClient = new QueryClient();
        queryClient.setQueryData([QueryKeys.CATEGORIES_INDEX], categories);
        queryClient.setQueryData([QueryKeys.MENUS_INDEX], menus);
        return {
            props: { dehydratedState: dehydrate(queryClient) },
            revalidate: process.env.NEXT_CACHE_TIME_REVALIDATE ? Number(process.env.NEXT_CACHE_TIME_REVALIDATE) : 50,
        };
    } catch (error) {
        return handleSsrError(error);
    }
};

export default function Index() {
    return (
        <Page
            url={PagesMain.index}
            title="Ручки с логотипом на заказ в Москве"
            Layout={MainLayout}
            Component={MainIndex}
            metaTagDescription="VivaGifts | Ручки с логотипом на заказ в Москве"
            metaTagKeywords="ручки, заказ"
        />
    );
}
